.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.admin-dashboard {
  text-align: center;
}

.dashboard-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.stats-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.stat-box {
  width: 150px;
  height: 150px;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.stat-box p {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.stat-box h2 {
  font-size: 1.5rem;
  margin: 0;
}
.admin-dashboard {
  text-align: center;
}

.dashboard-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.stats-container, .add-actions-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.stat-box, .action-box {
  width: 150px;
  height: 150px;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;
}

.stat-box:hover, .action-box:hover {
  transform: scale(1.05);
}

.stat-box p, .action-box p {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.stat-box h2 {
  font-size: 1.5rem;
  margin: 0;
}
/* Center title */
.dashboard-title {
  text-align: center;
  margin-bottom: 2rem;
}

.stats-container, .add-actions-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.stat-box, .action-box {
  width: 150px;
  height: 150px;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;
}

.stat-box:hover, .action-box:hover {
  transform: scale(1.05);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}

.modal-content h2 {
  margin-bottom: 1rem;
}

.modal-content input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-content button {
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-button {
  background-color: #e74c3c;
  color: white;
}

.close-button:hover {
  background-color: #c0392b;
}
  /* Admin Dashboard */
.dashboard-title {
  text-align: center;
  margin-bottom: 2rem;
}

.stats-container, .add-actions-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.stat-box, .action-box {
  width: 150px;
  height: 150px;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;
}

.stat-box:hover, .action-box:hover {
  transform: scale(1.05);
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}

.modal-content h2 {
  margin-bottom: 1rem;
}

.modal-content input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-content button {
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-button {
  background-color: #e74c3c;
  color: white;
}

.close-button:hover {
  background-color: #c0392b;
}
/* AdminDashboard.css */

table {
  width: 100%;
  border-collapse: collapse; /* Collapses borders */
  margin: 20px 0; /* Optional: adds some space above and below the table */
}

th, td {
  border: 1px solid #ddd; /* Adds a light gray border */
  padding: 8px; /* Adds padding for better readability */
  text-align: left; /* Align text to the left */
}

th {
  background-color: #f2f2f2; /* Light gray background for header */
  font-weight: bold; /* Bold text for header */
}

tr:hover {
  background-color: #f1f1f1; /* Optional: highlight row on hover */
}

.modal-overlay {
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
display: flex;
justify-content: center;
align-items: center;
}

.modal-content {
background: white;
padding: 20px;
border-radius: 5px;
max-width: 500px;
width: 100%;
position: relative; /* To position close button */
}
